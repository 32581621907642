import React, { useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import {
  FaChild,
  FaRegLightbulb,
  FaScaleBalanced,
  FaTree,
} from "react-icons/fa6";
import { GrGroup } from "react-icons/gr";
import { IoPersonCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const ViewPersonDetails = () => {
  const [formData] = useState({
    name: "बिनोद खड्का",
    pustaNum: "P001",
    spouse: "बिनिता खड्का",
    child: "आयुष्मान खड्का",
    father: "बिश्वनाथ खड्का",
    grandfather: "देवीप्रसाद खड्का",
    image: "https://pbs.twimg.com/media/F-q1xv2XgAAec9u.jpg",
    mother: "माया खड्का",
    events: ["Event 1", "Event 2"],
    children: ["Child Name 1", "Child Name 2"],
    siblings: ["Sibling 1", "Sibling 2"],
    fullSiblings: ["Full Sibling 1", "Full Sibling 2"],
    halfSiblings: ["Half Sibling 1", "Half Sibling 2"],
    stepSiblings: ["Step Sibling 1", "Step Sibling 2"],
  });

  const [activeTab, setActiveTab] = useState("personal");
  const navigate = useNavigate();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="md:py-2 flex gap-4 sm:gap-8">
      <div className="flex-1 bg-white py-4 flex flex-col gap-6">
        <div className="flex items-center justify-between px-6 sm:px-8">
          <div className="text-blue font-bold flex gap-4 items-center">
            <button
              onClick={() => {
                navigate(-1);
              }}
              className="border border-gray-300 p-1.5 rounded-md hover:bg-gray-100 transition-colors duration-300 ease-in-out"
            >
              <BiArrowBack className="w-5 h-5" />
            </button>

            <p>{formData.name}</p>
          </div>
        </div>

        <div className="flex flex-col gap-8 md:flex-row md:px-8 lg:px-[4.2rem] md:gap-16">
          <div className="md:w-[60%]">
            <div className="flex flex-col gap-5 px-6 sm:px-8 md:px-0">
              <div className="flex gap-4">
                <button
                  className={`py-1.5 px-2.5 flex items-center gap-1 text-[15px] rounded-md font-medium ${
                    activeTab === "personal"
                      ? "bg-blue text-white"
                      : "bg-gray-200 text-gray-700"
                  }`}
                  onClick={() => handleTabChange("personal")}
                >
                  <IoPersonCircle className="text-[22px]" />
                  Personal Details
                </button>

                <button
                  className={`py-1.5 px-2.5 flex items-center gap-1 text-[15px] rounded-md font-medium ${
                    activeTab === "children"
                      ? "bg-blue text-white"
                      : "bg-gray-200 text-gray-700"
                  }`}
                  onClick={() => handleTabChange("children")}
                >
                  <FaChild className="text-xl" />
                  Children
                </button>

                <button
                  className={`py-1.5 px-2.5 flex items-center gap-1 text-[15px] rounded-md font-medium ${
                    activeTab === "siblings"
                      ? "bg-blue text-white"
                      : "bg-gray-200 text-gray-700"
                  }`}
                  onClick={() => handleTabChange("siblings")}
                >
                  <GrGroup className="text-xl" />
                  Siblings
                </button>
              </div>

              {activeTab === "personal" && (
                <>
                  <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                    <h2 className="text-[15px] text-gray-900 font-bold md:flex-1">
                      नाम
                    </h2>
                    <p className="text-[15px] text-gray-700 font-medium md:flex-1">
                      {formData.name}
                    </p>
                  </div>

                  <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                    <h2 className="text-[15px] text-gray-900 font-bold md:flex-1">
                      पुस्ता नं.
                    </h2>
                    <p className="text-[15px] text-gray-700 font-medium md:flex-1">
                      {formData.pustaNum}
                    </p>
                  </div>

                  <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                    <h2 className="text-[15px] text-gray-900 font-bold md:flex-1">
                      जीवनसाथि
                    </h2>
                    <p className="text-[15px] text-gray-700 font-medium md:flex-1">
                      {formData.spouse}
                    </p>
                  </div>

                  <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                    <h2 className="text-[15px] text-gray-900 font-bold md:flex-1">
                      सन्तान
                    </h2>
                    <p className="text-[15px] text-gray-700 font-medium md:flex-1">
                      {formData.child}
                    </p>
                  </div>

                  <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                    <h2 className="text-[15px] text-gray-900 font-bold md:flex-1">
                      पिता
                    </h2>
                    <p className="text-[15px] text-gray-700 font-medium md:flex-1">
                      {formData.father}
                    </p>
                  </div>

                  <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                    <h2 className="text-[15px] text-gray-900 font-bold md:flex-1">
                      हजुरबुबा
                    </h2>
                    <p className="text-[15px] text-gray-700 font-medium md:flex-1">
                      {formData.grandfather}
                    </p>
                  </div>

                  <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                    <h2 className="text-[15px] text-gray-900 font-bold md:flex-1">
                      माता
                    </h2>
                    <p className="text-[15px] text-gray-700 font-medium md:flex-1">
                      {formData.mother}
                    </p>
                  </div>

                  <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                    <h2 className="text-[15px] text-gray-900 font-bold md:flex-1">
                      घटनाहरू
                    </h2>
                    <p className="text-[15px] text-gray-700 font-medium md:flex-1">
                      {formData.events && formData.events.join(", ")}
                    </p>
                  </div>
                </>
              )}

              {activeTab === "children" && (
                <>
                  {formData.children.map((child, index) => (
                    <div
                      key={index}
                      className="flex flex-col sm:flex-row sm:justify-between sm:items-center"
                    >
                      <h2 className="text-[15px] text-gray-900 font-bold md:flex-1">
                        Child {index + 1}
                      </h2>
                      <p className="text-[15px] text-gray-700 font-medium md:flex-1">
                        {child}
                      </p>
                    </div>
                  ))}
                </>
              )}

              {activeTab === "siblings" && (
                <>
                  {formData.siblings.map((sibling, index) => (
                    <div
                      key={index}
                      className="flex flex-col sm:flex-row sm:justify-between sm:items-center"
                    >
                      <h2 className="text-[15px] text-gray-900 font-bold md:flex-1">
                        Sibling {index + 1}
                      </h2>
                      <p className="text-[15px] text-gray-700 font-medium md:flex-1">
                        {sibling}
                      </p>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>

          <div className="px-6 sm:px-8 flex flex-col items-center gap-4 md:px-0 md:items-end md:w-[40%]">
            <div className="w-full max-w-[350px] h-[350px] lg:h-[300px] xl:h-[350px] relative overflow-hidden">
              <div className="flex w-full max-w-[350px] h-[350px] lg:h-[300px] xl:h-[350px] absolute transition-transform duration-300 ease-in-out">
                <img
                  className="w-full h-full object-cover"
                  src={formData.image}
                  alt="person"
                />
              </div>
            </div>

            <div className="flex flex-col gap-2.5 w-full max-w-[350px]">
              <button className="flex items-center justify-center gap-2 px-4 py-2 bg-blue text-white rounded-md hover:bg-blue-dark">
                <FaRegLightbulb className="text-xl" />
                Request a Suggestion
              </button>
              <button className="flex items-center justify-center gap-2 px-4 py-2 bg-green text-white rounded-md hover:bg-green-dark">
                <FaTree className="text-xl" />
                View Family Tree
              </button>
              <button className="flex items-center justify-center gap-2 px-4 py-2 bg-yellow text-white rounded-md hover:bg-yellow-dark">
                <FaScaleBalanced className="text-xl" />
                Compare
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPersonDetails;
