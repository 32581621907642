import React, { useState } from "react";
import BusinessLoginForm from "../components/Login/BusinessLoginForm";
import PersonalLoginForm from "../components/Login/PersonalLoginForm";
import RadioButton from "../components/common/RadioButton";

const LoginPage = () => {
  const [selectedAccountType, setSelectedAccountType] = useState("personal");
  const [selectedProcessType] = useState("login");
  //   const [verificationDigits, setVerificationDigits] = useState(["", "", "", ""]);
  //   const [isVerificationModalOpen, setVerificationModalOpen] = useState(false);

  return (
    <div className="w-full md:flex md:flex-row-reverse">
      <div className="p-6 py-10 md:w-[50%] lg:py-8 lg:px-[4%]">
        <div className="flex items-center gap-4 mb-6">
          <p className="text-2xl font-bold text-blue">Osis</p>
        </div>

        <div className="flex flex-col gap-8">
          <h1 className="text-center text-gray-900 text-2xl font-bold">
            Login to Osis
          </h1>

          <div className="flex flex-col gap-8">
            <div className="flex items-center justify-center gap-6 sm:gap-10 md:gap-16">
              <RadioButton
                id="personal"
                name="accountType"
                label="Personal account"
                defaultChecked={selectedAccountType === "personal"}
                onChange={() => setSelectedAccountType("personal")}
              />

              <RadioButton
                id="business"
                name="accountType"
                label="Business account"
                defaultChecked={selectedAccountType === "business"}
                onChange={() => setSelectedAccountType("business")}
              />
            </div>

            {selectedAccountType === "personal" && <PersonalLoginForm />}
            {selectedAccountType === "business" && (
              <BusinessLoginForm
              // setVerificationModalOpen={setVerificationModalOpen}
              />
            )}
          </div>
        </div>
      </div>

      <div className="hidden md:block md:fixed w-[50%] h-screen left-0 top-0">
        <img
          src="https://images.rawpixel.com/image_800/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvdjEwMTYtYy0wMi1rc2g2cWt1Zy5qcGc.jpg"
          alt=""
          className="min-w-full min-h-full object-cover"
        />
      </div>

      {/* {isVerificationModalOpen && (
        <VerificationModal
          setVerificationModalOpen={setVerificationModalOpen}
          verificationDigits={verificationDigits}
          setVerificationDigits={setVerificationDigits}
          selectedProcessType={selectedProcessType}
        />
      )} */}
    </div>
  );
};

export default LoginPage;
