import { atom, useAtom } from "jotai";
import React, { useState } from "react";
import { HiMinus, HiPhotograph, HiPlus } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

export const viewPersonPhotoHomepageAtom = atom(false);

const PersonInfoTableRow = ({ formData, index }) => {
  const [isMobileTableRowOpen, setIsMobileTableRowOpen] = useState(false);
  const [, setViewPersonPhotoHomepage] = useAtom(viewPersonPhotoHomepageAtom);

  const navigate = useNavigate();

  return (
    <>
      <div className="border-b-2 border-gray-400 px-5 py-3 sm:hidden">
        <div className="flex items-center gap-4">
          <div
            className={`w-6 h-6 flex justify-center items-center ${
              isMobileTableRowOpen
                ? "bg-blue text-white rounded-[4px]"
                : "border-2 border-gray-400 text-gray-400 rounded-sm"
            }`}
          >
            <button
              onClick={(event) => {
                event.preventDefault();
                setIsMobileTableRowOpen(!isMobileTableRowOpen);
              }}
            >
              {isMobileTableRowOpen ? (
                <HiMinus className="w-5 h-5" />
              ) : (
                <HiPlus className="w-5 h-5" />
              )}
            </button>
          </div>

          <div>
            <p className="text-gray-800 font-medium">{formData.name}</p>
          </div>
        </div>

        {isMobileTableRowOpen && (
          <div className="flex flex-col gap-2 mt-4 ml-[2.4rem]">
            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                पुस्ता नं.:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.pustaNum}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                जीवनसाथि:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.spouse}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                सन्तान:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.child}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                पिता:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.father}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                हजुरबुबा:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.grandfather}
              </p>
            </div>

            <div className="flex items-center gap-2 my-4">
              {/* <button className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]">
                <BiEdit className="w-5 h-5 text-blue" />
              </button>

              <button className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]">
                <BiTrash className="w-5 h-5 text-red-900" />
              </button> */}

              <button
                className="px-1.5 border-2 border-gray-300 text-gray-700 font-medium rounded-md h-[35px] flex items-center gap-1"
                onClick={(event) => {
                  event.stopPropagation();
                  setViewPersonPhotoHomepage(true);
                }}
              >
                <HiPhotograph className="w-[20px] h-[20px]" />
                View Photo
              </button>
            </div>
          </div>
        )}
      </div>

      <tr className="border-b-2 border-gray-300 text-gray-700 relative hidden sm:table-row hover:bg-gray-100">
        <td className="text-center">{index}</td>

        <td className="text-center">
          <button
            className="text-blue font-medium hover:underline"
            onClick={() => {
              const personId = formData.id;

              if (personId) {
                navigate(`/${personId}`);
              }
            }}
          >
            {formData.name}
          </button>
        </td>

        <td className="text-center">
          <button
            className="text-blue font-medium hover:underline"
            onClick={() => {
              const personId = formData.id;

              if (personId) {
                navigate(`/${personId}`);
              }
            }}
          >
            {formData.pustaNum}
          </button>
        </td>

        <td className="text-center">{formData.spouse}</td>

        <td className="text-center">
          <button
            className="text-blue font-medium hover:underline"
            onClick={() => {
              const personId = formData.id;

              if (personId) {
                navigate(`/${personId}`);
              }
            }}
          >
            {formData.child}
          </button>
        </td>

        <td className="text-center">
          <button
            className="text-blue font-medium hover:underline"
            onClick={() => {
              const personId = formData.id;

              if (personId) {
                navigate(`/${personId}`);
              }
            }}
          >
            {formData.father}
          </button>
        </td>

        <td className="text-center">
          <button
            className="text-blue font-medium hover:underline"
            onClick={() => {
              const personId = formData.id;

              if (personId) {
                navigate(`/${personId}`);
              }
            }}
          >
            {formData.grandfather}
          </button>
        </td>

        <td>
          <div className="flex items-center justify-center gap-2">
            {/* <button className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]">
              <BiEdit className="w-5 h-5 text-blue" />
            </button>

            <button className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]">
              <BiTrash className="w-5 h-5 text-red-900" />
            </button> */}

            <button
              className="px-1.5 border-2 border-gray-300 text-gray-700 font-medium rounded-md h-[35px] flex items-center gap-1"
              onClick={(event) => {
                event.stopPropagation();
                setViewPersonPhotoHomepage(true);
              }}
            >
              <HiPhotograph className="w-[20px] h-[20px]" />
              View Photo
            </button>
          </div>
        </td>
      </tr>
    </>
  );
};

export default PersonInfoTableRow;
