const PrimaryButton = ({ onClick, label, disabled }) => (
  <button
    onClick={onClick}
    className={`w-full bg-blue py-2 rounded-lg text-white text-[16px] font-semibold ${
      disabled ? "opacity-50 cursor-not-allowed" : ""
    }`}
    disabled={disabled}
  >
    {label}
    {disabled}
  </button>
);

export default PrimaryButton;
