import { atom, useAtom } from "jotai";
import React, { useEffect } from "react";
import "../../../styles/table.css";
import PersonInfoTableRow from "./PersonInfoTableRow";

const staticData = [
  {
    id: 1,
    name: "John Doe",
    pustaNum: "P001",
    spouse: "Jane Doe",
    child: "Alice Doe",
    father: "Doe Sr.",
    grandfather: "Doe Sr. Sr.",
  },
  {
    id: 2,
    name: "Jane Smith",
    pustaNum: "P002",
    spouse: "John Smith",
    child: "Bob Smith",
    father: "Smith Sr.",
    grandfather: "Smith Sr. Sr.",
  },
  // Add more data as needed
];

export const rowsPerPageAtom = atom(10);
// export const productIdAtom = atom(null);
export const dataAtom = atom([]);

const PersonInfoTable = ({ startIndex, endIndex, searchQuery }) => {
  const [data, setData] = useAtom(dataAtom);

  // Ensure searchQuery is defined before using toLowerCase()
  const lowerCaseSearchQuery = searchQuery ? searchQuery.toLowerCase() : "";

  // Filter data based on the search query
  const filteredData = data.filter((item) =>
    Object.values(item).some((value) => {
      if (value !== null && value !== undefined) {
        return value.toString().toLowerCase().includes(lowerCaseSearchQuery);
      }
      return false;
    })
  );

  useEffect(() => {
    async function fetchData() {
      try {
        setData(staticData);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="relative bg-white border-2 border-gray-400 overflow-x-auto whitespace-nowrap table-container">
      <table className="w-full text-white text-center border-spacing-4">
        <div className="w-full  border-gray-400 bg-gray-300 text-gray-900 text-[14px] font-bold px-5 py-3 uppercase sm:hidden">
          <h1>Person Details</h1>
        </div>

        <thead className="text-gray-900 border-y-2 border-gray-400 uppercase hidden sm:table-header-group">
          <tr>
            <th className="text-center">क्र.सं.</th>
            <th className="text-center">नाम</th>
            <th className="text-center">पुस्ता नं.</th>
            <th className="text-center">जीवनसाथि</th>
            <th className="text-center">सन्तान</th>
            <th className="text-center">पिता</th>
            <th className="text-center">हजुरबुबा</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>

        <tbody className="text-gray-700">
          {filteredData.slice(startIndex, endIndex).map((item, index) => (
            <PersonInfoTableRow
              key={index + 1}
              index={index + 1}
              formData={item}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PersonInfoTable;
