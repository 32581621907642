import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import InputWithLabel from "../common/InputWithLabel";
import PrimaryButton from "../common/PrimaryButton";

const PersonalLoginForm = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const [error, setError] = useState("");

  const validateEmailOrPhone = (input) => {
    const emailRegex =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    const phoneRegex = /^[0-9]{10}$/;

    if (emailRegex.test(input)) {
      return "email";
    } else if (phoneRegex.test(input)) {
      return "phone";
    } else {
      return "invalid";
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { username, password } = formData;

    if (!username || !password) {
      setError("Please fill in all fields.");
    } else {
      const validationType = validateEmailOrPhone(username);

      if (validationType === "invalid") {
        setError("Please enter a valid email or phone number.");
      } else {
        setError("");

        navigate('/seller_registration');

        const url = process.env.REACT_APP_BASE_URL + "/auth/login/";
        
        // Perform form submission logic here
      }
    }
  };

  return (
    <form
      action=""
      className="self-start w-full flex flex-col items-start gap-5 lg:px-[10%]"
    >
      <InputWithLabel
        htmlFor="username"
        label="Email or phone number"
        type="text"
        placeholder="Lexialexandra@gmail.com"
        name="username"
        onChange={handleInputChange}
        value={formData.username}
      />

      <div className="relative w-full">
        <InputWithLabel
          htmlFor="password"
          label="Password"
          type="password"
          placeholder="Helloecommerce"
          name="password"
          onChange={handleInputChange}
          value={formData.password}
        />

        <button className="absolute right-0 top-0 text-[14px] text-blue-800 font-medium">
          Forgot Password?
        </button>
      </div>
      {error && <p className="text-red-900 text-[14px] font-medium">{error}</p>}

      <div className="flex items-center gap-2">
        <input type="checkbox" name="" id="" className="w-3.5 h-3.5" />
        <label htmlFor="" className="text-gray-500 text-[14px]">
          Remember me ?
        </label>
      </div>

      <PrimaryButton onClick={handleSubmit} label="Sign in" />

      <div className="flex text-[14px] text-gray-900 items-center gap-2 self-center">
        <p>Doesn't have an account?</p>
        <Link to="/signup">
          <button className="font-semibold">Signup</button>
        </Link>
      </div>
    </form>
  );
};

export default PersonalLoginForm;