import { useAtom } from "jotai";
import React, { useEffect, useRef, useState } from "react";
import { HiChevronDown, HiOutlineSearch } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import PersonInfo from "../components/Homepage/PersonInfo/PersonInfo";
import { viewPersonPhotoHomepageAtom } from "../components/Homepage/PersonInfo/PersonInfoTableRow";

const Homepage = () => {
  const [filterStatus, setFilterStatus] = useState("नाम");
  const [searchQuery, setSearchQuery] = useState("");
  const [showMobileDropdown, setShowMobileDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const [viewPersonPhotoHomepage, setViewPersonPhotoHomepage] = useAtom(
    viewPersonPhotoHomepageAtom
  );
  const imageRef = useRef(null);

  const handleSearchInputChange = (event) => {
    if (event && event.target && event.target.value !== undefined) {
      setSearchQuery(event.target.value);
    }
  };

  const handleMobileOptionSelect = (option) => {
    setFilterStatus(option);
    setShowMobileDropdown(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowMobileDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        viewPersonPhotoHomepage &&
        imageRef.current &&
        !imageRef.current.contains(event.target)
      ) {
        setViewPersonPhotoHomepage(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [viewPersonPhotoHomepage, setViewPersonPhotoHomepage]);

  return (
    <div className={`px-4 sm:px-8 py-4 sm:py-6 flex flex-col gap-4`}>
      <div className="flex items-stretch justify-end gap-4 lg:items-center">
        <div className="relative min-w-[120px]" ref={dropdownRef}>
          <button
            className="w-full flex items-center justify-between gap-4 text-blue font-bold text-[16px] bg-white border-2 border-gray-300 rounded-md px-2 py-1.5"
            onClick={() => setShowMobileDropdown(!showMobileDropdown)}
          >
            <p>{filterStatus}</p>
            <HiChevronDown className="text-[20px]" />
          </button>

          {showMobileDropdown && (
            <div className="absolute z-30 mt-2 left-0 w-full bg-white border border-gray-300 rounded-md">
              {["नाम", "क्र.सं.", "पुस्ता नं.", "Phone", "हजुरबुबा"].map(
                (option) => (
                  <button
                    key={option}
                    className={`w-full ${
                      filterStatus === option ? "bg-gray-200" : ""
                    } cursor-pointer hover:bg-gray-200 p-1.5`}
                    onClick={(e) => {
                      handleMobileOptionSelect(option);
                      e.stopPropagation();
                    }}
                  >
                    {option}
                  </button>
                )
              )}
            </div>
          )}
        </div>

        <div className="flex gap-4">
          <div className="px-4 py-2 flex items-center gap-2 bg-white border border-gray-300 rounded-md cursor-pointer md:cursor-pointer">
            <HiOutlineSearch className="text-gray-500 text-[20px]" />

            <input
              type="text"
              placeholder="खोज"
              className="hidden bg-white outline-none font-medium text-[15px] placeholder:text-[15px] w-[200px] placeholder:text-gray-400 md:block"
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
          </div>
        </div>
      </div>

      <PersonInfo searchQuery={searchQuery} />

      {viewPersonPhotoHomepage && (
        <div className="fixed z-50 top-0 left-0 w-full h-full bg-black bg-opacity-70 flex items-center justify-center">
          <div className="relative w-[80%] h-[80%]">
            <img
              src="https://pbs.twimg.com/media/F-q1xv2XgAAec9u.jpg"
              alt="person"
              className="w-full h-full object-contain"
              ref={imageRef}
            />
          </div>

          <button
            className="absolute top-5 right-5 text-white text-[20px] rounded-md"
            onClick={() => setViewPersonPhotoHomepage(false)}
          >
            <IoClose className="text-3xl" />
          </button>
        </div>
      )}
    </div>
  );
};

export default Homepage;
