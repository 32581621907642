import React, { useState } from "react";
import { Link } from "react-router-dom";
import InputWithLabel from "../common/InputWithLabel";
import PrimaryButton from "../common/PrimaryButton";

const BusinessLoginForm = ({ setVerificationModalOpen }) => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const [error, setError] = useState("");

  const Phone = (input) => {
    const phoneRegex = /^[0-9]{10}$/;

    if (phoneRegex.test(input)) {
      return "phone";
    } else {
      return "invalid";
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { username, password } = formData;

    if (!username || !password) {
      setError("Please fill in all fields.");
    } else {
      const validationType = Phone(username);

      if (validationType === "invalid") {
        setError("Please enter a valid phone number.");
      } else {
        setError("");

        const url = process.env.REACT_APP_BASE_URL + "/auth/login/";

        

        // Perform form submission logic here
      }
    }
  };

  return (
    <form
      action=""
      className="self-start w-full flex flex-col items-start gap-10 lg:px-[10%]"
    >
      <div className="w-full flex flex-col gap-2">
        <div className="flex flex-col gap-6">
          <div className="flex flex-col items-start gap-1.5">
            <label
              htmlFor="username"
              className="text-[14px] uppercase text-gray-800 font-bold"
            >
              Phone Number
            </label>

            <div className="w-full border border-gray-400 rounded-lg flex">
              <div className="w-16 bg-[#F3F3F6] text-center text-gray-500 text-[14px] font-medium rounded-tl-lg rounded-bl-lg py-2">
                +977
              </div>

              <input
                type="text"
                placeholder="9852545652"
                onChange={handleInputChange}
                value={formData.username}
                name="username"
                className="outline-none w-full rounded-tr-lg rounded-br-lg px-4 py-2 text-[14px] font-medium placeholder:text-gray-300 placeholder:font-normal"
              />
            </div>
          </div>

          <div className="relative">
            <InputWithLabel
              htmlFor="password"
              label="Password"
              type="password"
              placeholder="Helloecommerce"
              name="password"
              onChange={handleInputChange}
              value={formData.password}
            />

            <button className="absolute right-0 top-0 text-[14px] text-blue-800 font-medium">
              Forgot Password?
            </button>
          </div>
        </div>

        {error && (
          <p className="text-red-900 text-[14px] font-medium">{error}</p>
        )}

        <div className="flex items-center gap-2">
          <input type="checkbox" name="" id="" className="w-3.5 h-3.5" />
          <label htmlFor="" className="text-gray-500 text-[14px]">
            Remember me?
          </label>
        </div>
      </div>

      <div className="w-full flex flex-col gap-5 items-center">
        <PrimaryButton onClick={handleSubmit} label="Sign in" />

        <div className="flex text-[14px] text-gray-900 items-center gap-2 self-center">
          <p>Doesn't have an account?</p>
          <Link to="/signup">
            <button className="font-semibold">Signup</button>
          </Link>
        </div>
      </div>
    </form>
  );
};

export default BusinessLoginForm;