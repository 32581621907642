import { BrowserRouter, Route, Routes } from "react-router-dom";
import ViewPersonDetails from "./components/Homepage/PersonInfo/ViewPersonDetails";
import Homepage from "./pages/Homepage";
import LoginPage from "./pages/LoginPage";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/:id" element={<ViewPersonDetails />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
